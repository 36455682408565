import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
    
  <div class="pa-3">

      <uploadingDialog v-bind:uploadingDialog = "this.uploadingDialog"></uploadingDialog>
      <mySnackBar      v-bind:MySnackBar      = "this.MySnackBar"></mySnackBar>

      <v-container>

      <v-row class="pt-2 pa-1 mb-2 d-flex justify-space-between align-baseline">
              
           <div>Test Certificate Form</div>
            
            <div>   
            <v-btn  v-if = "this.$router.currentRoute.path != '/'"  @click="formReviewUpload" class="mx-2" fab dark small color="red darken-3">
              <v-icon dark>mdi-file-pdf</v-icon>
            </v-btn>
          
            <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
              <v-icon dark>mdi-presentation-play</v-icon>
            </v-btn>
            
            </div>

      </v-row>
      </v-container>

      <v-row dense justify="center">
         <v-expansion-panels class="expansion_panels">
 
              <!-- Instructions -- remove 9/3/2021
              <v-expansion-panel class="expansion_panel mb-1"> 
                <v-expansion-panel-header>Test Certificate Instructions</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="header text-justify">
                 </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              -->
              
              <!-- customerComponent -->
                  <customer v-bind:customer = "customer" />
              <!-- customerComponent -->

              <!-- Installation Details-->
                  <v-expansion-panel class="expansion_pane mb-1"> 
                  <v-expansion-panel-header>Installation Details</v-expansion-panel-header>
                    <v-expansion-panel-content>

                      <v-container>
                        <v-row>

                              <v-col cols="12" sm="4">
                                    <v-text-field  v-model = "installationDetails.category" outlined dense label="Installation Category:"></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="4">
                                    <v-text-field v-model = "installationDetails.use" outlined dense label="Use:"></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="4">
                                    <v-text-field v-model = "installationDetails.wseReference" outlined dense label="W.S.E Reference:"></v-text-field>
                              </v-col>
                    
                        </v-row>
                      </v-container>
                  
                  </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Installation Details-->

              <!-- examination -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                    <v-expansion-panel-header>Bulk Tank Examination</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <hr>
                      <v-container>
                       <v-row dense>
     
                          <v-col cols="12" sm="6">  
                              <v-radio-group label = "Bulk Tank Periodic Examination"  v-model  = "examination.examination">
                                <v-radio label="INSPECTION"        value="INSPECTION"></v-radio>
                                <v-radio label="REPORT"            value="REPORT"></v-radio>
                                <v-radio label="CERTIFICATE"       value="CERTIFICATE"></v-radio>
                              </v-radio-group>
                          </v-col>
                     
                          <v-col cols="12" sm="6">  
                          <v-radio-group label = "Examination Type "  v-model  = "examination.examinationType">
                            <v-radio label="ANNUAL"                value="ANNUAL"></v-radio>
                            <v-radio label="IN-SERVICE"            value="IN-SERVICE"></v-radio>
                            <v-radio label="ENHANCED IN-SERVCE"    value="ENHANCED IN-SERVICE"></v-radio>
                            <v-radio label="THOROUGH"              value="THOROUGH"></v-radio>
                          </v-radio-group>
                          </v-col>
                        </v-row>
        
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- Examination -->

              <!-- Vessel -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                  <v-expansion-panel-header dense >Vessel</v-expansion-panel-header>
                     <v-expansion-panel-content class="pa-0">
                      
                      <hr>

                      <v-container>
                      <v-row dense>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.serialNumber" label="Serial Number"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.namePlateStamped" label="Name plate stamped (test code)"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.manufacturer" label="Manufacturer"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.manufacturerTestDate" label="Manufacturers test date"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.previousInserviceTestDateCode" label="Previous in-service test date code"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">  
                          <v-text-field outlined dense v-model="vessel.previousThoroughTestDateCode" label="Previous thorough test date code"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4">  
                          <v-text-field outlined dense v-model="vessel.size" label="Size"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4">  
                          <v-text-field outlined dense v-model="vessel.designCode" label="Design Code"></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4">  
                          <v-text-field outlined dense v-model="vessel.designPressure" label="Design Pressure"></v-text-field>
                      </v-col>
                      </v-row>

                      <hr>

                      <v-row dense>
                      <v-col cols="12" sm="6">  
                      <v-radio-group  label="Colour:"           v-model="vessel.colour">
                             <v-radio label="Underground"       value="Underground"></v-radio>
                             <v-radio label="Green"             value="Green"></v-radio>
                             <v-radio label="Pale Green"        value="Pale Green"></v-radio>
                             <v-radio label="White"             value="White"></v-radio>
                      </v-radio-group>
                      </v-col>
                     
                      <v-col cols="12" sm="6">  
                      <v-radio-group  label="Use"                   v-model="vessel.use">
                             <v-radio label="Vap"                   value="Vap"></v-radio>
                             <v-radio label="Top Liq"               value="Top Liq"></v-radio>
                             <v-radio label="Bottom Liq"            value="Bottom Liq"></v-radio>
                      </v-radio-group>
                      </v-col>
                      </v-row>
                     
                      </v-container>
                     
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- vessel Details -->
            
              <!-- Relief Valve(s)-->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                   <v-expansion-panel-header dense >Relief Valve(s)</v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <hr>
                      <v-container>
                       <v-row dense v-for="(item, index) in reliefValves" :key="index">

                            <v-col cols="12">
                                  <v-checkbox dense v-model="item.enabled" :label=" 'Valve No: ' + (index + 1)"></v-checkbox>
                            </v-col>
                            
                            <v-col cols="12" sm="3">
                                  <v-text-field  outlined dense v-model="item.manufacturer"  :disabled = "!item.enabled" label="Manufacturer"></v-text-field>
                            </v-col>
                            
                            <v-col cols="12" sm="2">
                                    <v-text-field  outlined dense v-model="item.prv"         :disabled = "!item.enabled"  label="PRV"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="2">
                                    <v-text-field  outlined dense v-model="item.rva"        :disabled = "!item.enabled"  label="RVA"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="2">
                                    <v-text-field  outlined dense v-model="item.pressure"        :disabled = "!item.enabled"  label="PSI/Bar"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="3">
                                    <DateSelector
                                       caption           =  "Test date"
                                       v-bind:enabled    =  "item.enabled"
                                       v-bind:curDate    =  "item.testDate"
                                       @updated="(e) => { item.testDate = e}"
                                    ></DateSelector>
                            </v-col>

                       </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!--  Relief Valves-->

              <!-- checklist
                   <v-expansion-panel class="expansion_panel mb-1"> 
                    <v-expansion-panel-header dense >Checklist</v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                
                    <hr>

                    <v-container>
                      <v-simple-table dense>
                    
                            <thead>
                              <tr>
                                <th class="a1 text-left">
                                  
                                </th>
                                <th class="a1 text-left">
                                  N/A
                                </th>
                                <th class="a1 text-left">
                                  Yes/No
                                </th>
                                
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(item, index) in checkList" :key="index" >
                                  <td class="des a1">{{ item.name }}</td>
                                  <td class="a1"><input v-model="item.disabled" type="checkbox"></td>
                                  <td class="a1">
                                <toggle-button
                                    :disabled = "item.disabled"
                                    :value = "item.value"
                                    :labels = "{checked: 'Yes', unchecked: 'No'}"
                                    :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                                    color  = "#82C7EB"
                                    :width = "60"
                                    :height = "28"
                                    v-model= "item.value"
                                    @change= "(v) => item.value"
                                  />
                                  </td>
                              </tr>
                            </tbody>
                      </v-simple-table>
                    
                    <hr>
                        <v-radio-group  label="Tank Checked for corrosion and was found to be:" v-model="tankCorrosionLevel">
                          <v-radio label="Satisfactory"      value="satisfactory"></v-radio>
                          <v-radio label="Surface Corrosion" value="Surface Corrosion"></v-radio>
                          <v-radio label="Serverly corroded" value="Severely corroded"></v-radio>
                        </v-radio-group>

                    </v-container>
                  
                    </v-expansion-panel-content>
                   </v-expansion-panel>
                  checklist-->

              <!-- checklist-->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                    <v-expansion-panel-header dense >Checklist</v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                    <hr>

                    <v-container>

                      <v-simple-table dense>
                    
                            <thead>
                              <tr>
                                  <th class="a1 text-left">
                                  </th>
                                  <th class="a1 text-left">
                                   N/A
                                  </th>
                                  <th class="a1 text-left">
                                   Yes
                                  </th>
                                   <th class="a1 text-left">
                                   No
                                  </th>
                                  
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="(item, index) in checkList" :key="index" >
                                  <td class="des a1">{{ item.name }}</td>
                                  <td class="a1">
                                          <input type="radio" :name="item.index" value="NA"  v-model="item.value">
                                  </td>
                                  <td class="a1">
                                          <input type="radio" :name="item.index" value="YES" v-model="item.value">
                                  </td> 
                                   <td class="a1">
                                          <input type="radio" :name="item.index" value="NO"  v-model="item.value">
                                  </td>

                              </tr>
                            </tbody>
                      </v-simple-table>
                    
                    <hr>
                        <v-radio-group  label="Tank Checked for corrosion and was found to be:" v-model="tankCorrosionLevel">
                          <v-radio label="Satisfactory"      value="Satisfactory"></v-radio>
                          <v-radio label="Surface Corrosion" value="Surface Corrosion"></v-radio>
                          <v-radio label="Serverly corroded" value="Severely Corroded"></v-radio>
                        </v-radio-group>

                    </v-container>
                  
                    </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- checklist-->

              <!-- Ultra Sonic Thickness check -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                         <v-expansion-panel-header dense >Ultrasonic Thickness Check</v-expansion-panel-header>
         
                         <v-expansion-panel-content class="pa-1">
                             <div class="des"> Grid (All Tanks) Test Readings (mm)<br/>
                               <span class="bold">only when specified by WSE procedure</span>
                             </div>
                              <hr>

                            <v-row>
                         
                            <v-col cols="12">
                              <span class="mt-5 diagram-wrapper">
                                  <img class="diagram-wrapper-img" src="../../assets/diagram_tank.png" alt="">
                              </span> 
                            </v-col>
 
                            </v-row>
                         
                            <v-container>

                              <v-row class="pb-2" dense >
                                   <strong>CONTROL READINGS:</strong>
                              </v-row >
                         
                              <v-row dense >
                               <v-col cols="3" sm="6">
                                    <v-text-field   type="number" v-model = "ultraSonicControl.A" dense label="A"> </v-text-field>
                                </v-col>
                               <v-col cols="3" sm="6">
                                    <v-text-field  type="number" v-model = "ultraSonicControl.B" dense label="B"> </v-text-field>
                                </v-col>
                               <v-col cols="3" sm="6">
                                    <v-text-field  type="number" v-model = "ultraSonicControl.C" dense label="C"> </v-text-field>
                                </v-col>
                               <v-col cols="3" sm="6">
                                    <v-text-field  type="number" v-model = "ultraSonicControl.D" dense label="D"> </v-text-field>
                                </v-col>
                              </v-row>
                             </v-container>

                             <hr>

                              <v-row dense class="mt-4" >
                                 <v-col v-for="(item, index) in ultraSonic" :key="index"  cols="2" sm="1">
                                    <v-text-field  type="number" dense outlined v-model="item.value" :label="item.id"> </v-text-field>
                                 </v-col>
                              </v-row>
                  
                              <hr>
  
                              <!-- not required 9/3/2021 -->  
                              <!-- now required 11/30/2021 moved to PassorFail section
                              <v-container>
                              <v-row dense>
                                <v-col cols="12" sm="8">
                                    <label class="bold">Date after which vessel shall not be used without further examination</label>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field  dense outlined label="25"> </v-text-field>
                                </v-col>
                              </v-row>
                              </v-container>
                              -->

  
                         </v-expansion-panel-content>

                  </v-expansion-panel>
              <!-- checklist 2 -->

              <!-- Pass/FAIL -->
                  <v-expansion-panel class="expansion_panel mb-1"> 
                         <v-expansion-panel-header dense >Inspector Pass/Fail</v-expansion-panel-header>
                            <v-expansion-panel-content class="pa-1">
                              <hr>
                               <v-container>

                              <v-row dense >
                               <v-col cols="12" sm="4">
                                    <v-text-field  dense v-model="passOrFail.employersName" label="Employer's Name"> </v-text-field>
                                </v-col>
                              <!--
                               <v-col cols="12" sm="6">
                                    <v-text-field  dense v-model="passOrFail.dateExamined" label="Date Examined"> </v-text-field>
                                </v-col>
                              -->
                               <v-col cols="12" sm="4">
                                    <v-text-field  dense v-model="passOrFail.inspectorsName" label="Inspector's Name"> </v-text-field>
                                </v-col>
                               <v-col cols="12" sm="4">
                                    <v-text-field  dense v-model="passOrFail.inspectorsCertNo" label="Inspector Certificate No:"> </v-text-field>
                                </v-col>
                              </v-row>

                              <v-row dense >

                                <v-col cols="12">
                                  <v-textarea outlined v-model="passOrFail.inspectorsComments" label="Inspector comments:"> </v-textarea>
                                  <v-radio-group       v-model="passOrFail.vesselSatisfactory" label="Vessel satisfactory for further service :"> 
                                   <v-radio label="Yes"      value="YES"></v-radio>
                                   <v-radio label="No"       value="NO"></v-radio>
                                  </v-radio-group>
                                </v-col>

                              </v-row>

                              <v-row dense >
                                      <DateSelector
                                       caption           =  "Date after which vessel shall not be used without further examination"
                                       v-bind:enabled    =  "true"
                                       v-bind:curDate    =  "passOrFail.lastUseDate"
                                       @updated="(e) => { passOrFail.lastUseDate = e}"
                                    ></DateSelector>
                              </v-row>

                              <v-row dense >
                                        <div class="passfailvessel mr-2">PASS / FAIL Vessel:</div> 
                                        <toggle-button 
                                        v-model = "passOrFail.vesselPassFail"
                                        :labels = "{checked: 'PASS', unchecked: 'FAIL'}"
                                        :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                                        color  = "#82C7EB"
                                        :width = "80"
                                        :height = "38"
                                        :value = "passOrFail.vesselPassFail"
                                      />
                              </v-row>


                               </v-container>
                          </v-expansion-panel-content>
                  </v-expansion-panel>
              <!-- pass/fail -->

              <!-- last one for space-->
                <v-expansion-panel class="expansion_panel mb-1"> 
                </v-expansion-panel>
               <!-- last one for space-->


         </v-expansion-panels>
      </v-row>

  </div>

</template>

<script>

import {store} from '../../main.js'
import Localbase from 'localbase'


export default {
    name      : "testCertificate",
    formName  : "testCertificate",
    computed  : {
                  slicedVessels(){
                   return this.vesselsLimit ? this.vessels.slice(0, this.vesselsLimit) : this.vessels
                }
    },
    methods   : {

               saveDocument( customer, documentKey, documentObject ){
                    let db     = new Localbase( this.$dbName );

                    db.collection( customer ).doc(documentKey).set( documentObject )
                    .then(response => {
                      console.log( response)
                    })
                    .catch(error => {
                      console.log( error)
                    })
               },

               retrieveDocument( customer, documentKey ){
                    let db = new Localbase(  this.$dbName );

                    db.collection(customer).doc(documentKey).get()
                    .then( lastestObject => {
                      if ( lastestObject != null ) {
                          this.installationDetails  = lastestObject.formReviewData.installationDetails;
                          this.vessel               = lastestObject.formReviewData.vessel;
                          this.reliefValves         = lastestObject.formReviewData.reliefValves;
                          this.ultraSonic           = lastestObject.formReviewData.ultraSonic;
                          this.examination          = lastestObject.formReviewData.examination;
                          this.passOrFail           = lastestObject.formReviewData.passOrFail;
                          this.tankCorrosionLevel   = lastestObject.formReviewData.tankCorrosionLevel;
                          this.checkList            = lastestObject.formReviewData.checkList;
                          this.ultraSonicControl    = lastestObject.formReviewData.ultraSonicControl;
                      }
                    })
                    .catch( error => {
                      console.log( error)
                    });

               },

               showSnackBar( message = "You need to review the form first..."){
                    this.MySnackBar.text          =  message;
                    this.MySnackBar.showImage     = "icon";
                    this.MySnackBar.show          = true;
                    setTimeout( () => { this.MySnackBar.show  = false }, 1000)
               },

               showUploadingDialog( message = "Uploading PDF...") {
                         this.uploadingDialog.message    = message;
                         this.uploadingDialog.showDialog = true;
               },

               closeUploadingDialog( message = "PDF Uploaded...") {
                          this.uploadingDialog.message    = message;
                          setTimeout( () => {
                                    this.uploadingDialog.message    = "";
                                    this.uploadingDialog.showDialog = false;
                                 }, 2000)
               },

               generateSaveObject() {
                          let saveObject   =  { 
                                                     "formReviewName"    : "testCertificate",
                                                     "formReviewURL"     :  this.$restServer.testCertificateURL,
                                                     "formReviewData"    :  {  
                                                                            "requestedResponse"           : "pdf",
                                                                            "customer"                    :  this.customer,
                                                                            "salesman"                    :  this.salesman,
                                                                            "engineer"                    :  this.engineer,
                                                                            "installationDetails"         :  this.installationDetails,
                                                                            "vessel"                      :  this.vessel,
                                                                            "reliefValves"                :  this.reliefValves,
                                                                            "ultraSonic"                  :  this.ultraSonic,
                                                                            "ultraSonicControl"           :  this.ultraSonicControl,
                                                                            "examination"                 :  this.examination,
                                                                            "passOrFail"                  :  this.passOrFail ,
                                                                            "checkList"                   :  this.checkList,
                                                                            "tankCorrosionLevel"          :  this.tankCorrosionLevel,
                                                                          } 
                                                    };
                          return saveObject;                          

               }, 

               generateLoggingObject() {
                     return  { 
                               "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                               "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                               "formName"    : this.formName 
                             };
               },

               formReviewUpload() {
            
                       const url                       =  this.$restServer.uploadPDFToDropBoxURL;
                       const formReviewReponse         =  store.retrieveFormReviewResponse();
                       
                       if ( formReviewReponse  ) {

                            let   self        = this;
                            formReviewReponse['loggingObject']     = this.generateLoggingObject();
                            this.showUploadingDialog();
                            
                            this.$http.post( url, formReviewReponse ) 
                            .then(  (response) => {
                                  store.resetFormReviewResponse();
                                  self.closeUploadingDialog();
                            })
                            .catch( (e) => { 
                                  self.closeUploadingDialog(e.message);
                                  localStorage.removeItem("equalResponsePdfReviewObject");
                                  console.log(e);
                            });
  
                       } else {
                            this.showSnackBar("You need to review the form first...");
                       }

               },
               
               formReview(e){
                    try {
                          var saveObject  =  this.generateSaveObject();
                          store.saveFormObject("testCertificate", saveObject)
                          this.$router.push( { name : "FormReview", "params" : saveObject } );

                    } catch (e) {
                       alert(e);
                    }
               },
    },
    data() {
      return {
         MySnackBar        :  { "show"       : false, "text" : "Default Text", "showImage" : "icon"},
         uploadingDialog   :  { "showDialog" : false, "message": "" },
         formName              : "testCertificate",
         customer              :  {},
         salesman              :  {} ,
         engineer              :  {} ,
         tankCorrosionLevel    :  "",
         passfailvessel        :  "",
         installationDetails   :  {
                                    category:"",
                                    use:"",
                                    wseReference:""
         },
         vessel              :  {
                                    "serialNumber"                    : "",
                                    "namePlateStamped"                : "",
                                    "manufacturer"                    : "",
                                    "manufacturerTestDate"            : "",
                                    "previousInserviceTestDateCode"   : "",
                                    "previousThoroughTestDateCode"    : "",
                                    "size"                            : "",
                                    "colour"                          : "",
                                    "designCode"                      : "",
                                    "designPressure"                  : "",
                                    "use"                             : "",                                        
         },
         checkList           :  [
                              { "disabled": true, "id": "1", "name":"Multi Valve fitted",                                     "value" : "NA" },
                              { "disabled": true, "id": "2", "name":"Fill connection has got a manual shut-off fitted",       "value" : "NA" },
                              { "disabled": true, "id": "3", "name":"External surfaces and welds cleaned",                    "value" : "NA" },
                              { "disabled": true, "id": "5", "name":"All valves, fittings and ancillary equipment examined",  "value" : "NA" },
                              { "disabled": true, "id": "6", "name":"Pressure Gauge (if fitted) removed and plugged",         "value" : "NA" },
                              { "disabled": true, "id": "7", "name":"Relief valve(s) replaced and fitted witbn rain caps",    "value" : "NA" },
                              { "disabled": true, "id": "8", "name":"Foundations satisfactory",                               "value" : "NA" },
                              { "disabled": true, "id": "9", "name":"Fixed liquid level checked",                             "value" : "NA" },
                              { "disabled": true, "id": "10", "name":"Float gauge and screws checked",                        "value" : "NA" },
                              { "disabled": true, "id": "11", "name":"Hood serviceable and lockable",                         "value" : "NA" },
                              { "disabled": true, "id": "12", "name":"Earthing points satisfactory",                          "value" : "NA" },
                              { "disabled": true, "id": "13", "name":"All Connections checked for leaks",                     "value" : "NA" },
                              { "disabled": true, "id": "14", "name":"Vapour off-take valve operational check",               "value" : "NA" },
                              { "disabled": true, "id": "15", "name":"Hose examineed",                                        "value" : "NA" },
                              { "disabled": true, "id": "16", "name":"ACME filler Valve",                                     "value" : "NA" },
                              { "disabled": true, "id": "16a", "name":" - New sealing washer fitted and cap satisfactory",    "value" : "NA" },
                              { "disabled": true, "id": "16b", "name":" - Manual shut-off valve checked",                     "value" : "NA" },
                              { "disabled": true, "id": "17", "name":"Tank paintwork satisfactory",                           "value" : "NA" },
                              { "disabled": true, "id": "18", "name":"Site condition satisfactory (COP1)",                    "value" : "NA" },
                              { "disabled": true, "id": "19", "name":"Tank and compound labels satisfactory",                 "value" : "NA" },
                              { "disabled": true, "id": "20", "name":"Overhaul and service pull away couplings",              "value" : "NA" },
                              { "disabled": true, "id": "21", "name":"Grease pump if required",                               "value" : "NA" },
                              { "disabled": true, "id": "22", "name":"Calibrate meter if fitted",                             "value" : "NA" },
                              { "disabled": true, "id": "23", "name":"Clean strainer if fitted",                              "value" : "NA" },
                              { "disabled": true, "id": "18", "name":"HRV's replaced",                                        "value" : "NA" },
                              { "disabled": true, "id": "19", "name":"Is underground pipe polyethylene",                      "value" : "NA" },
         ], 
         ultraSonicControl   : {  
                                    "A" : "",
                                    "B" : "",
                                    "C" : "",
                                    "D" : "",
         },
         ultraSonic          :  [
                              
                              { "id": "1",  "value" : "" },
                              { "id": "2",  "value" : "" },
                              { "id": "3",  "value" : "" },
                              { "id": "4",  "value" : "" },
                              { "id": "5",  "value" : "" },
                              { "id": "6",  "value" : "" },
                              { "id": "7",  "value" : "" },
                              { "id": "8",  "value" : "" },
                              { "id": "9",  "value" : "" },
                              { "id": "10", "value" : "" },
                              { "id": "11", "value" : "" },
                              { "id": "12", "value" : "" },
                              { "id": "13", "value" : "" },

                              { "id": "14", "value" : "" },
                              { "id": "15", "value" : "" },
                              { "id": "16", "value" : "" },
                              { "id": "17", "value" : "" },
                              { "id": "18", "value" : "" },
                              { "id": "19", "value" : "" },
                              { "id": "20", "value" : "" },
                              { "id": "21", "value" : "" },
                              { "id": "22", "value" : "" },
                              { "id": "23", "value" : "" },
                              { "id": "24", "value" : "" },
                              { "id": "25", "value" : "" },
         ], 
         examination : {
              "examinationType"         : "",
              "examination"             : ""
         },
         reliefValves         : [
                                { 
                                  "enabled"      :  false,
                                  "manufacturer" :  "",
                                  "prv"          :  "",
                                  "rva"          :  "",
                                  "pressure"     :  "",
                                  "testDate"     :  "", 
                                }, {
                                  "enabled"      : true,
                                  "manufacturer" : "",
                                  "prv"          : "",
                                  "rva"          : "",
                                  "pressure"     :"",
                                  "testDate"     :"", 
                                }
         ],
         enhancedTest         : [
                                  { "enabled": false,  "value": "", "label" : "Vapour off-take valve checked for let by"},
                                  { "enabled": false,  "value": "", "label" : "Are regulators fitted with upso/opso protection and built in limited relief" },
                                  { "enabled": false,  "value": "", "label" : "if upso/opso protection fitted has installation been upgraded"},
                                  { "enabled": false,  "value": "", "label" : "Tightness test of service pipework"}
         ],
         passOrFail           :  {
                            employersName           : "",
                            dateExamined            : "",
                            inspectorsName          : "",
                            inspectorsCertNo        : "",
                            inspectorsComments      : "",
                            vesselSatisfactory      : "NO",
                            vesselPassFail          : false,
                            lastUseDate             : "",

         },
 
      };
    },

    created    (){

        store.consoleLog( "testCertificate created");
        this.customer         = JSON.parse( localStorage.getItem("equalResponseCustomer") );
        this.salesman         = JSON.parse( localStorage.getItem("equalResponseSalesman") );
        this.engineer         = JSON.parse( localStorage.getItem("equalResponseEngineer") );

        //  reload the last testCertificate if any         //
        const customer           = this.customer['ACCOUNT'];
        const documentKey        = this.$options.formName;
        this.retrieveDocument( customer, documentKey);

        /*
        var lastestObject     = store.retrieveFormObject("testCertificate");
        if ( lastestObject != null ) {
             this.installationDetails  = lastestObject.formReviewData.installationDetails;
             this.vessel               = lastestObject.formReviewData.vessel;
             this.reliefValves         = lastestObject.formReviewData.reliefValves;
             this.ultraSonic           = lastestObject.formReviewData.ultraSonic;
             this.examination          = lastestObject.formReviewData.examination;
             this.passOrFail           = lastestObject.formReviewData.passOrFail;
             this.tankCorrosionLevel   = lastestObject.formReviewData.tankCorrosionLevel;
             this.checkList            = lastestObject.formReviewData.checkList;
             this.ultraSonicControl    = lastestObject.formReviewData.ultraSonicControl;
        }
        */
    },

    destroyed  () {

           store.consoleLog( "testCertificate destroyed");

           let saveObject           =  this.generateSaveObject();
           const customer           =  this.customer['ACCOUNT'];
           const documentKey        =  this.$options.formName;
           this.saveDocument( customer, documentKey, saveObject );

          /*  store.saveFormObject("testCertificate", saveObject) */
    },
  
};
</script>

<style>

legend.v-label {
    font-size: small !important;
    font-weight: bold !important;
}

.a1{
  padding-left: 0 !important;
}

.a2{
  padding: 0 !important;
}

.parent {
    display: flex;
    flex-wrap: wrap;
}

.des {
    font-size: small !important;
}

.bold {
    font-size: small !important;
    font-weight: bold;
}



.v-expansion-panel-content__wrap{
  padding: 0 6px 6px;
}

.nowrap-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.diagram-wrapper {

    padding: 10px;
    background: #fff;
    display: inline-block;
    margin-bottom: 10px;
}

.diagram-wrapper-img { 
   max-width: 100%;
    height: auto;
}

.vue-js-switch#changed-font {
  font-size: 16px;
}

.passfailvessel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>
